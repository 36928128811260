.logo {
    margin: 0px 10px 0px 30px;
}

header {
    position: relative;
    z-index: 1;
    background-color: white !important;
    border-radius: 100%/0 0 5px 5px;

}

header::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: #00614b;
    border-radius: 100%/0 0 5px 5px;

}
