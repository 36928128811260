@import url('https://fonts.googleapis.com/css?family=Bebas+Neue');
@import url('https://fonts.googleapis.com/css?family=Tenor+Sans');
@import url('https://fonts.googleapis.com/css?family=Montserrat');

body {
    margin: 0;
    padding: 0;
    height: 100%;
    /*letter-spacing: 0.1em;*/

    font-family: 'tenor open sans-serif', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: bold !important;

}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
    min-height: 100%;
    font-weight: bold !important;
}

#root {
    height: 100%;
}

