@import url(https://fonts.googleapis.com/css?family=Bebas+Neue);
@import url(https://fonts.googleapis.com/css?family=Tenor+Sans);
@import url(https://fonts.googleapis.com/css?family=Montserrat);
body {
    margin: 0;
    padding: 0;
    height: 100%;
    /*letter-spacing: 0.1em;*/

    font-family: 'tenor open sans-serif', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: bold !important;

}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
    min-height: 100%;
    font-weight: bold !important;
}

#root {
    height: 100%;
}


.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.acc {
    width: 100% !important;
    height: 100% !important;
}

#react-qrcode-logo {
    width: 100% !important;
    height: unset !important;
    padding: 8px;
}

.MuiOutlinedInput-notchedOutline {
    border: hidden !important;
}

.css-18ilvf9-MuiBottomNavigationAction-label {
    font-size: 0.8em !important;
}

.MuiListItemText-primary {
    font-size: 0.6em !important;
}
/*.css-cio0x1-MuiInputBase-root-MuiFilledInput-root:before {*/
/*    border-bottom: none !important;*/
/*}*/

/*.css-cio0x1-MuiInputBase-root-MuiFilledInput-root {*/
/*    border-top-left-radius: 0px;*/
/*    border-top-right-radius: 0px;*/
/*    border-radius: 20px !important;*/
/*}*/

/*.css-cio0x1-MuiInputBase-root-MuiFilledInput-root:after {*/
/*    border-bottom: none !important;*/
/*}*/

/*.css-d01l19-MuiInputBase-root-MuiFilledInput-root:before {*/
/*    border-bottom: none !important;*/
/*}*/

/*.css-d01l19-MuiInputBase-root-MuiFilledInput-root {*/
/*    border-top-left-radius: 0px;*/
/*    border-top-right-radius: 0px;*/
/*    border-radius: 20px !important;*/
/*}*/

/*.css-d01l19-MuiInputBase-root-MuiFilledInput-root:after {*/
/*    border-bottom: none !important;*/
/*}*/

/*.css-oh8vrv-MuiInputBase-root-MuiFilledInput-root:before {*/
/*    border-bottom: none !important;*/
/*}*/

/*.css-oh8vrv-MuiInputBase-root-MuiFilledInput-root {*/
/*    border-top-left-radius: 0px;*/
/*    border-top-right-radius: 0px;*/
/*    border-radius: 20px !important;*/
/*}*/

/*.css-oh8vrv-MuiInputBase-root-MuiFilledInput-root:after {*/
/*    border-bottom: none !important;*/
/*}*/

/*.css-d9oaum-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input {*/
/*    padding-top: 8px !important;*/
/*}*/

/*.css-d01l19-MuiInputBase-root-MuiFilledInput-root.Mui-focused {*/
/*    background-color: #F0B90B !important;*/
/*}*/

/*.css-oh8vrv-MuiInputBase-root-MuiFilledInput-root.Mui-focused.Mui-focused {*/
/*    background-color: #8247E5 !important;*/
/*}*/

/*.logoLogin {*/
/*    max-height: 200px;*/
/*    min-height: 100px;*/
/*}*/

.logo {
    margin: 0px 10px 0px 30px;
}

header {
    position: relative;
    z-index: 1;
    background-color: white !important;
    border-radius: 100%/0 0 5px 5px;

}

header::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: #00614b;
    border-radius: 100%/0 0 5px 5px;

}

