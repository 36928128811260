.acc {
    width: 100% !important;
    height: 100% !important;
}

#react-qrcode-logo {
    width: 100% !important;
    height: unset !important;
    padding: 8px;
}
